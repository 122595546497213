<template>
  <router-view></router-view>
</template>

<style>
h1 {
  font-size: 2rem;
  margin: 1rem 0;
}
.topbar {
  justify-content: center!important;
  border-bottom: none;
}
.card {
  margin: 100px auto;
  max-width: 320px;
}
</style>